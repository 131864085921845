var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"live_tracking_main",staticStyle:{"padding":"0","background":"white"}},[_c('v-card',{staticStyle:{"box-shadow":"none"}},[_c('v-card-title',{staticClass:"white--text caption primary",staticStyle:{"position":"sticky","top":"0px","z-index":"10"},attrs:{"id":"live_tracking_title"}},[(!_vm.isLargeDevice)?_c('v-row',{staticClass:"mt-1"},[_c('div',{staticClass:"shape accent",staticStyle:{"opacity":"0.6"}})]):_vm._e(),_c('v-row',{staticClass:"description"},[_c('v-col',{staticStyle:{"padding":"0","display":"flex","align-items":"center"},attrs:{"cols":"3"}},[_c('div',[_c('v-img',{staticStyle:{"border-radius":"100%"},attrs:{"width":"80px","height":"80px","contain":"","lazy-src":require('../../../assets/riderPin.png'),"src":_vm.hasShopImage
                  ? _vm.voucherData.shopid.shopImage
                  : require('../../../assets/riderPin.png')}})],1)]),_c('v-col',{staticStyle:{"word-break":"break-word","padding":"0 10px","align-self":"center"},attrs:{"cols":"9"}},[_c('div',{staticClass:"text-left font-size-16 m-b-5"},[_vm._v(" "+_vm._s(_vm.getStatusDetails.title)+" ")]),_c('div',{staticClass:"text-left font-size-14"},[_vm._v(" "+_vm._s(_vm.getStatusDetails.subtitle)+" ")])])],1)],1),_c('v-card-text',{staticStyle:{"padding":"0"}},[_c('v-tabs',{staticClass:"slider pl-5",staticStyle:{"background":"white","position":"sticky","z-index":"10","padding-left":"0 !important","box-shadow":"0px 2px 3px 0px #949494"},style:({ top: 110 + 'px' }),attrs:{"light":"","color":"white","background-color":"white","height":"100%"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.allTabs),function(tab){return _c('v-tab',{key:tab.id,staticStyle:{"text-transform":"initial","padding":"0 4px"},on:{"click":function($event){_vm.isComponent = tab.id}}},[_c('div',{staticClass:"m-r-10 m-b-5",style:({
              background: _vm.isActiveButton(tab.id).background,
              color: _vm.isActiveButton(tab.id).color,
              padding: '18px',
              fontSize: '14px',
              textTransform: 'initial',
              borderRadius: '20px',
              height: '26px',
              minWidth: '50px',
              display: 'flex',
              alignItems: 'center',
              margin: '0',
              border: '1px solid black',
            })},[_vm._v(" "+_vm._s(tab.title)+" ")])])}),1),_c('v-container',[_c(_vm.renderedComponent,{tag:"component",staticStyle:{"margin-bottom":"80px !important"},attrs:{"voucherData":_vm.voucherData},on:{"changeTab":function($event){return _vm.changeTab($event)}}}),_c('div',{staticClass:"bottom_fixed_button"},[(_vm.isComponent !== 'courierManagement' && !_vm.isDisabled)?_c('v-btn',{staticClass:"ma-2",staticStyle:{"text-transform":"initial"},attrs:{"height":"50","width":"100%","color":"primary"},on:{"click":function () {
                _vm.isComponent = 'courierManagement';
                _vm.tab = 2;
              }}},[_c('div',{staticClass:"font-weight-bold font-size-16",staticStyle:{"display":"flex","justify-content":"space-between","width":"100%"}},[_c('div',[_vm._v("Διαχείριση Διανομής")]),_c('div',[_c('font-awesome-icon',{staticClass:"m-r-20 font-size-20",attrs:{"icon":_vm.faChevronRight}})],1)])]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }