<template>
  <div
    v-if="clientData && isRendered"
    :class="{ LT_main_large: isLargeDevice }"
  >
    <TrackingMap
      :class="{ LT_map_large: isLargeDevice, LT_map_small: !isLargeDevice }"
      :clientDetails="clientData"
      :riderDetails="riderDetails"
    ></TrackingMap>
    <MainWrapper
      :class="{
        LT_wrapper_large: isLargeDevice,
        LT_wrapper_small: !isLargeDevice,
      }"
    ></MainWrapper>
  </div>
  <div v-else-if="!clientData && !initialLoad">
    <v-alert dense type="error"> Δεν βρέθηκε κάποιο voucher </v-alert>
  </div>
</template>

<script>
import TrackingMap from "./LiveTracking-Components/TrackingMap.vue";
import MainWrapper from "@/views/LiveTracking/LiveTracking-Components/MainWrapper";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default {
  components: {
    TrackingMap,
    MainWrapper,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  metaInfo: function () {
    return {
      title: "Stoferno.gr | Live Tracking",
      meta: [{ name: "description", content: "Courier live tracking" }],
    };
  },
  data() {
    return {
      riderDetails: null,
      initialLoad: false,
      isRendered: true,
    };
  },
  watch: {
    isLargeDevice: {
      handler() {
        this.isRendered = false;
        this.$nextTick(() => {
          this.isRendered = true;
        });
      },
      // immediate: true,
    },
  },
  computed: {
    ...mapState({
      clientData: (state) => state["livetracking/livetracking"].voucherData,
    }),
    ...mapGetters({
      isLargeDevice: "device/isLargeDevice",
    }),
  },
  methods: {
    ...mapActions({
      getVoucherData: "livetracking/livetracking/getVoucherData",
    }),
    ...mapMutations({
      updateVoucherData: "livetracking/livetracking/VOUCHER_DATA_RESPONSE",
    }),
    initializeIntercom() {
      this.$intercom.boot({
        user_id: `https://app.stoferno.gr/courier-tracking/order/${this.id} - User`,
        name: `${this.clientData.clientName} - ${this.id} - ${this.clientData.optimization.idBeforeOptim}`,
        email: `${this.clientData.clientMobile}`,
        phone: `${this.clientData.clientEmail}`,
      });
    },
    initializaSockets() {
      const vm = this;
      this.removeSubscriptions();
      this.sockets.subscribe("courierTrackingDetails", (newOrderDetails) => {
        this.updateVoucherData({
          vm,
          response: newOrderDetails.result,
        });
        if (
          newOrderDetails.result.completed ||
          newOrderDetails.result.cancelled
        ) {
          this.removeSubscriptions();
          this.riderDetails = null;
        }
      });
      this.sockets.subscribe("position", (courierPosition) => {
        this.$socket.emit("getCourierTrackingOrderDetails", this.id);
        this.riderDetails = courierPosition;
      });
    },
    removeSubscriptions() {
      this.sockets.unsubscribe("courierTrackingDetails");
      this.sockets.unsubscribe("position");
    },
  },
  created() {
    this.initialLoad = true;
    this.getVoucherData({
      id: this.id,
      vm: this,
    })
      .then((_) => {
        this.initialLoad = false;
        this.initializeIntercom();
        let rider = this.clientData.optimization.riderToComplete;
        if (rider && !this.clientData.completed && !this.clientData.cancelled) {
          this.initializaSockets();
          this.$socket.emit("getposition", rider);
        }
      })
      .catch((err) => {
        this.initialLoad = false;
        console.log(err);
      });
  },
  beforeDestroy() {
    this.removeSubscriptions();
    this.$intercom.shutdown();
  },
};
</script>

<style lang="scss">
.seasonImage {
  color: #fff;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000;
}
.LT_map_small {
  height: 30vh !important;
}
.LT_wrapper_small {
  .v-slide-group__prev {
    display: none !important;
  }
}
.LT_main_large {
  display: flex;
  flex-direction: row-reverse;
  height: 100vh;
  overflow: hidden;
  .LT_map_large {
    height: 100vh;
  }
  .LT_wrapper_large {
    overflow: auto;
    width: 80%;
    z-index: 1;
    box-shadow: 3px 0px 3px -2px rgb(0 0 0 / 20%);
  }
}
</style>
