const getFullDateFromTimestamp = (timestamp) => {
  let date = `${timestamp.getDate()}/${
    timestamp.getMonth() + 1
  }/${timestamp.getFullYear()}`;
  let time = `${timestamp.getHours()}:${timestamp.getMinutes()}`;
  let splitTime = time.split(":");
  if (parseInt(splitTime[0]) < 10) {
    splitTime[0] = 0 + splitTime[0];
  }
  if (parseInt(splitTime[1]) < 10) {
    splitTime[1] = 0 + splitTime[1];
  }
  splitTime = splitTime.join(":");
  return `${date} ${splitTime}`;
};

// example date 19/03/2021 22:00:00
const getTimestampFromDate = (date) => {
  let dateSplit = date.split(" ");
  let dateReversed = dateSplit[0].split("/").reverse().join("/");
  let formatDate = `${dateReversed} ${dateSplit[1]}`;
  return Math.round(new Date(formatDate).getTime() / 1000);
};

const timeDifference = (dates) => {
  let difference = new Date(dates[0]).getTime() - new Date(dates[1]).getTime();
  return Math.abs(Math.floor(difference / 1000 / 60 / 60 / 24));
};

const getDifferenceInHours = (timestamp1, timestamp2) => {
  let distance = timestamp2 - timestamp1;
  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  if (days) {
    return `${days} μέρα(ες)`;
  } else if (hours) {
    return `${hours} ώρα(ες)`;
  } else {
    return `${minutes} λεπτό(ά)`;
  }
};

export {
  getFullDateFromTimestamp,
  getTimestampFromDate,
  timeDifference,
  getDifferenceInHours,
};
