<template>
  <l-map
    ref="trackingMap"
    class="map"
    style="height: 100%; width: 100%"
    :zoom="zoom"
    :center="center"
  >
    <l-tile-layer :url="url"></l-tile-layer>
    <l-marker
      v-if="riderData"
      :lat-lng="[riderData.lat, riderData.lon]"
      :icon="riderPin"
    >
      <l-popup
        :content="
          appendHTML(riderData.name, formatTimeDiff(riderData.timestamp))
        "
      />
    </l-marker>
    <l-marker
      v-if="clientData.clientAddress.lat && clientData.clientAddress.lon"
      :lat-lng="[clientData.clientAddress.lat, clientData.clientAddress.lon]"
      :icon="handlePin"
    >
      <l-popup
        :content="appendHTML(clientData.name, clientData.clientAddress.text)"
      />
    </l-marker>
    <v-img
      src="@/assets/stoferno.png"
      width="120px"
      style="float: right; z-index: 1000; margin: 10px"
      contain
    ></v-img>
  </l-map>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { getDifferenceInHours } from "@/common-js/timeConverts";
export default {
  props: {
    clientDetails: {
      type: Object,
    },
    riderDetails: {
      type: Object,
    },
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  data() {
    return {
      url: "https://api.mapbox.com/styles/v1/nikoskous/ckai8484k0o6d1imzsq0xko7r/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoibmlrb3Nrb3VzIiwiYSI6ImNrMnRjajM5NDBxZXczbXA1YmxueGdhcmMifQ.O0Fz93cltHQ10OfqGBS7FQ",
      zoom: 10,
      center: [38.0192238, 23.8407044],
      greenPin: L.icon({
        iconUrl: require("@/assets/greenPin.png"),
        iconSize: [25, 35.92],
      }),
      redPin: L.icon({
        iconUrl: require("@/assets/redPin.png"),
        iconSize: [25, 35.92],
      }),
      orangePin: L.icon({
        iconUrl: require("@/assets/orangePin.png"),
        iconSize: [25, 35.92],
      }),
      blackPin: L.icon({
        iconUrl: require("@/assets/blackPin.png"),
        iconSize: [25, 35.92],
      }),
      riderPin: L.icon({
        iconUrl: require("@/assets/riderPin.png"),
        iconSize: [38.6, 38.6],
      }),
    };
  },
  computed: {
    riderData() {
      if (!this.riderDetails) return null;
      return {
        lat: this.riderDetails.lat,
        lon: this.riderDetails.lon,
        name: `${this.riderDetails.riderDets.user.name}`,
        timestamp: this.riderDetails.timestamp,
      };
    },
    clientData() {
      if (!this.clientDetails) return null;
      return {
        clientAddress: {
          lat: this.clientDetails.clientAddress.lat,
          lon: this.clientDetails.clientAddress.lon,
          text: this.clientDetails.clientAddress.text,
        },
        name: this.clientDetails.clientName,
      };
    },
    handlePin() {
      if (!this.clientDetails) return null;
      let inProgress = this.clientDetails.trackingHistory.find((el) =>
        el.status.includes("Deliver to Destination")
      );
      if (this.clientDetails.cancelled) return this.redPin;
      else if (this.clientDetails.completed) return this.greenPin;
      else if (inProgress) return this.orangePin;
      else return this.blackPin;
    },
  },
  methods: {
    formatTimeDiff(timestamp) {
      const dateNow = new Date().getTime();
      let diff = getDifferenceInHours(timestamp, dateNow);
      return `Τελευταία ενημέρωση πριν: ${diff}`;
    },
    appendHTML(name, text) {
      return `<div>
          ${name ? `<b>${name}</b> <br>` : ""}
          <b> ${text} </b>
        </div>`;
    },
  },
};
</script>

<style lang="scss">
.map {
  z-index: 1;
}
</style>
