<template>
  <div class="live_tracking_main" style="padding: 0; background: white">
    <v-card style="box-shadow: none">
      <v-card-title
        id="live_tracking_title"
        class="white--text caption primary"
        style="position: sticky; top: 0px; z-index: 10"
      >
        <v-row v-if="!isLargeDevice" class="mt-1">
          <div class="shape accent" style="opacity: 0.6"></div>
        </v-row>
        <v-row class="description">
          <v-col
            cols="3"
            style="padding: 0; display: flex; align-items: center"
          >
            <div>
              <v-img
                width="80px"
                height="80px"
                contain
                style="border-radius: 100%"
                :lazy-src="require('../../../assets/riderPin.png')"
                :src="
                  hasShopImage
                    ? voucherData.shopid.shopImage
                    : require('../../../assets/riderPin.png')
                "
              />
            </div>
          </v-col>
          <v-col
            cols="9"
            style="word-break: break-word; padding: 0 10px; align-self: center"
          >
            <div class="text-left font-size-16 m-b-5">
              {{ getStatusDetails.title }}
            </div>
            <div class="text-left font-size-14">
              {{ getStatusDetails.subtitle }}
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="padding: 0">
        <v-tabs
          light
          class="slider pl-5"
          style="
            background: white;
            position: sticky;
            z-index: 10;
            padding-left: 0 !important;
            box-shadow: 0px 2px 3px 0px #949494;
          "
          :style="{ top: 110 + 'px' }"
          v-model="tab"
          color="white"
          background-color="white"
          height="100%"
        >
          <v-tab
            v-for="tab in allTabs"
            :key="tab.id"
            style="text-transform: initial; padding: 0 4px"
            @click="isComponent = tab.id"
          >
            <div
              class="m-r-10 m-b-5"
              :style="{
                background: isActiveButton(tab.id).background,
                color: isActiveButton(tab.id).color,
                padding: '18px',
                fontSize: '14px',
                textTransform: 'initial',
                borderRadius: '20px',
                height: '26px',
                minWidth: '50px',
                display: 'flex',
                alignItems: 'center',
                margin: '0',
                border: '1px solid black',
              }"
            >
              {{ tab.title }}
            </div>
          </v-tab>
        </v-tabs>
        <v-container>
          <component
            style="margin-bottom: 80px !important"
            :voucherData="voucherData"
            :is="renderedComponent"
            @changeTab="changeTab($event)"
          ></component>
          <div class="bottom_fixed_button">
            <v-btn
              v-if="isComponent !== 'courierManagement' && !isDisabled"
              @click="
                () => {
                  isComponent = 'courierManagement';
                  tab = 2;
                }
              "
              height="50"
              width="100%"
              class="ma-2"
              color="primary"
              style="text-transform: initial"
            >
              <div
                class="font-weight-bold font-size-16"
                style="
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                "
              >
                <div>Διαχείριση Διανομής</div>
                <div>
                  <font-awesome-icon
                    :icon="faChevronRight"
                    class="m-r-20 font-size-20"
                  ></font-awesome-icon>
                </div>
              </div>
            </v-btn>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
export default {
  components: {
    LiveTrackingDetails: () =>
      import(
        "@/views/LiveTracking/LiveTracking-Components/LiveTrackingDetails"
      ),
    QRdetails: () =>
      import("@/views/LiveTracking/LiveTracking-Components/QRdetails"),
    CourierManagement: () =>
      import("@/views/LiveTracking/LiveTracking-Components/CourierManagement"),
  },
  data() {
    return {
      faChevronRight,
      isComponent: "liveTracking",
      titleHeight: "",
      tab: null,
      allTabs: [
        { title: "Live Tracking", id: "liveTracking" },
        // { title: "QR Επιβεβαίωσης", id: "QR" },
        { title: "Διαχείριση Διανομής", id: "courierManagement" },
      ],
    };
  },
  computed: {
    ...mapState({
      voucherData: (state) => state["livetracking/livetracking"].voucherData,
    }),
    ...mapGetters({
      isLargeDevice: "device/isLargeDevice",
    }),
    renderedComponent() {
      const obj = {
        liveTracking: "LiveTrackingDetails",
        QR: "QRdetails",
        courierManagement: "CourierManagement",
      };
      return obj[this.isComponent];
    },
    hasShopImage() {
      return !!(
        this.voucherData &&
        this.voucherData.shopid.shopImage &&
        this.voucherData.shopid.shopImage !== "noImge"
      );
    },
    isDisabled() {
      return this.voucherData.completed || this.voucherData.cancelled;
    },
    getStatusDetails() {
      let lastDelivery = null;
      let lastBulkNotif = null;
      this.voucherData.trackingHistory.forEach((el, i) => {
        if (el.status.includes("Delivery to destination")) {
          let splitText = el.title.split(": ");
          lastDelivery = {
            title: splitText[0],
            subtitle: splitText[1],
          };
          lastBulkNotif = null;
        } else if (el.status.includes("Bulk notification")) {
          lastBulkNotif = {
            title: "Νέο μήνυμα από διαχειριστή",
          };
          lastDelivery = null;
        }
      });
      if (this.voucherData.cancelled) {
        return {
          title: "Η διανομή ακυρώθηκε",
          subtitle: null,
          // color: "error",
        };
      } else if (this.voucherData.completed) {
        return {
          title: "Ολοκληρώθηκε με επιτυχία",
          subtitle: null,
          // color: "success",
        };
      } else if (!this.voucherData.trackingActions.approvedDetails) {
        return {
          title: "Νέο αίτημα διανομής",
          subtitle: "Επιβεβαιώστε τα στοιχεία σας",
          // color: "primary",
        };
      } else if (lastDelivery) {
        return {
          title: lastDelivery.title,
          subtitle: lastDelivery.subtitle,
          // color: "primary",
        };
      } else if (lastBulkNotif) {
        return {
          title: "Νέο μήνυμα από διαχειριστή",
          subtitle: null,
          // color: "primary",
        };
      } else {
        return {
          title: "Σε εξέλιξη",
          subtitle: "Συντομα θα λάβετε νέα ενημέρωση",
          // color: "primary",
        };
      }
    },
  },
  methods: {
    isActiveButton(option) {
      if (option === this.isComponent) {
        return {
          color: "white",
          background: "black",
        };
      } else {
        return {
          color: "black",
          background: "white",
        };
      }
    },
    changeTab(tab) {
      this.isComponent = tab;
    },
  },
  mounted() {
    // setTimeout(() => {
    //   let height = document.getElementById("live_tracking_title").offsetHeight;
    //   console.log(height);
    //   this.$set(this, "titleHeight", height - 1);
    // }, 0);
    this.$nextTick(function () {});
  },
};
</script>

<style lang="scss">
.live_tracking_main {
  .caption {
    flex-direction: column;
    height: 110px;
  }
  .shape {
    height: 6px;
    width: 60px;
    // background-color: lightgrey;
    border-radius: 20px;
  }
  .description {
    width: 100%;
  }
  .slider {
    width: 100%;
    height: 64px;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .bottom_fixed_button {
    left: 0%;
    position: fixed;
    z-index: 10;
    bottom: 20px;
    right: 20px;
    width: fit-content;
    height: 60px;
    margin-top: 8px;
  }
}
</style>
